import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact-us.css";
import { useParams } from "react-router-dom";

function ContactUs({ data }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState({});
  const [emailError, setEmailError] = useState({});
  const [mobileNumError, setMobileNumError] = useState({});
  const [contactUsCms, setContactUsCms] = useState([]);
  const [contactUsChildCms, setContactUsChildCms] = useState([]);
  const [admissionFormCms, setAdmissionFormCms] = useState([]);

  const { id } = useParams();

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/contact-us`);
    if (res) {
      setContactUsCms(res.data?.data);
      setContactUsChildCms(res.data?.data.childPages);
    }

    const response = await makeRequest(`/cms/admission-form`);
    if (response) {
      setAdmissionFormCms(response.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  const nameValidation = () => {
    if (!name.match(/^[a-zA-Z\s]+$/)) {
      return false;
    }
    return true;
  };

  const numberValidation = () => {
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (regex.test(message) === false) {
      return false;
    }
    return true;
  };

  const emailValidation = () => {
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = formValidation();
    if (!isValid) return;

    if (isValid) {
      const data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };

      if (id) {
        const data = {
          name: name,
          email: email,
          trainingName: id,
          subject: id,
          message: message,
        };
        const res = await makeRequest(`/trainings/application`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
          }, 3000);
        }
      } else {
        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
          }, 3000);
        }
      }
    }
  };

  const formValidation = () => {
    const nameError = {};
    const emailError = {};
    const mobileNumError = {};
    let isValid = true;

    if (nameValidation() === false) {
      nameError.invalidName = "Name must contain letters only!";
      isValid = false;
    }
    if (emailValidation() === false) {
      setEmailError("Invalid Email Address!");
      isValid = false;
    }
    if (id) {
      if (numberValidation() === false) {
        mobileNumError.invalidNumber = "Phone number must be 10 digits!";
        isValid = false;
      }
    }
    setNameError(nameError);
    setEmailError(emailError);
    setMobileNumError(mobileNumError);
    return isValid;
  };

  const handleSubject = (e) => {
    if (id) {
      setSubject(id);
    } else {
      setSubject(e.target.value);
    }
  };

  return (
    <div
      className="container-fluid px-0 contact-wrapper"
      style={{ background: data.background }}
    >
      <div className="container content-main">
        <div className="content-left">
          <h1 style={{ color: data.color }}>
            {id
              ? admissionFormCms[0]?.title
              : "Get in touch with the awesome quick response team!"}
          </h1>
          <p style={{ color: data.color }}>
            {id
              ? removeHTML(admissionFormCms[0]?.description)
              : removeHTML(contactUsCms?.description)}
          </p>
          {contactUsChildCms.length > 0 && (
            <div className="contact-details">
              <ul className="menu" style={{ color: data.color }}>
                {contactUsChildCms.map((child, index) => {
                  return (
                    <li key={index}>
                      <img
                        src={getFileUrl(child.image)}
                        style={{ width: "18px", filter: data.filter }}
                        alt=""
                      />
                      {"\u00a0\u00a0"}
                      {child.description && removeHTML(child.description)}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="content-right">
          <h1>{id ? "Admission Form" : "Contact Sales Team"}</h1>
          <form onSubmit={handleSubmit}>
            <input
              required
              type="text"
              name="name"
              placeholder="Your Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {Object.keys(nameError).map((key) => {
              return (
                <div
                  key={nameError}
                  style={{ color: "red", fontSize: "small" }}
                >
                  {nameError[key]}
                </div>
              );
            })}

            <input
              required
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {Object.keys(emailError).map((key) => {
              return (
                <div
                  key={emailError}
                  style={{ color: "red", fontSize: "small" }}
                >
                  {emailError[key]}
                </div>
              );
            })}

            {id && (
              <div>
                <input
                  required
                  type="text"
                  name="message"
                  placeholder="Mobile Number"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {Object.keys(mobileNumError).map((key) => {
                  return (
                    <div
                      key={mobileNumError}
                      style={{ color: "red", fontSize: "small" }}
                    >
                      {mobileNumError[key]}
                    </div>
                  );
                })}
              </div>
            )}

            {id ? (
              <input
                readOnly
                disabled
                required
                type="text"
                name="subject"
                placeholder="Subject"
                value={id ? id : subject}
              />
            ) : (
              <input
                required
                type="text"
                name="subject"
                placeholder="Subject"
                value={id ? id : subject}
                onChange={(e) => handleSubject(e)}
              />
            )}
            {!id && (
              <textarea
                placeholder="A short message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            )}
            <button type="submit" className="btn-submit">
              {id ? "Book Your Seat" : "Send Message"}
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
