import { useEffect, useState } from "react";
import "./careers.css";
import { Button, Form } from "react-bootstrap";
import makeRequest from "../../utils/api";
import { ParseHTML, removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Accordion from "../../components/accordion/accordion";

function Careers() {
  const [careers, setCareers] = useState([]);
  const [careerCategory, setCareerCategory] = useState([]);
  const [q, setQ] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [careerCMS, setCareerCMS] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [careerID, setCareerID] = useState("");
  const [CV, setCV] = useState(null);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setShow(false);
    const career = document.getElementById("career");
    const navigation = document.getElementById("navigation-wrap");
    career.style.opacity = "100%";
    navigation.style.opacity = "100%";
  };

  const handleShow = (title) => {
    setCareerID(title);
    setShow(true);
    const career = document.getElementById("career");
    const navigation = document.getElementById("navigation-wrap");
    career.style.opacity = "60%";
    navigation.style.opacity = "60%";
  };

  const fetchItems = async () => {
    const params = new URLSearchParams();
    params.set("take", (8).toString());

    setIsLoading(true);
    const res = await makeRequest(`/careers?${params.toString()}`, {
      method: "GET",
    });
    if (res) {
      setCareers(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/careers/category`, {
      method: "GET",
    });
    if (res) {
      setCareerCategory(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCMS = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/cms/career`, {
      method: "GET",
    });
    if (res) {
      setCareerCMS(res.data?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchCategory();
    fetchCMS();
  }, []);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    } else {
      const data = new FormData();

      data.append("name", name);
      data.append("position", careerID);
      data.append("email", email);
      data.append("coverLetter", message);
      data.append("file", CV);

      const res = await makeRequest(`/careers/applications`, {
        method: "POST",
        data: data,
      });
      if (res) {
        toast.success(res.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    setValidated(true);
  };

  const searchHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = careers.filter((career) => {
        if (career.title.includes(text.toLowerCase())) {
          return career;
        }
      });
    }
    setSuggestions(matches);
    setQ(text);

    var dropdown = document.getElementById("dropdown-menu");
    if (suggestions.length > 0) {
      dropdown.style.display = "block";
    }
    if (text.length === 0) {
      dropdown.style.display = "none";
    }
  };

  return (
    <>
      <Helmet>
        <title>Careers</title>
        <meta name="description" content={careerCMS.metaDescription} />
      </Helmet>
      <main>
        <section className="careers" id="career">
          <div className="container careers-wrapper">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="content-top text-center">
                  <div className="content">
                    <h1>
                      {careerCMS.description &&
                        removeHTML(careerCMS.description)}
                    </h1>
                    {/* <form className="row g-3 justify-content-center">
                                <div className="col-auto">
                                    <label htmlFor="inputPassword2" className="visually-hidden">Password</label>
                                    <input type="text" className="form-control" id="inputPassword2" name="q" value={q} onChange={e => searchHandler(e.target.value)} placeholder="Search" autoComplete='off'/>
                                    <div class="dropdown-menu" id='dropdown-menu' style={{ "width": "406px", "gap": "0.5rem" }}>
                                    <div class="list-autocomplete">
                                                {suggestions && suggestions.map((suggestion, index) =>
                                                    <div key={index}><li className='suggest-item' style={{ "padding": "2px 10px" }} onClick={(e) => suggestHandler(suggestion.title)}>{suggestion.title}</li></div>)}
                                            </div>
                                        {suggestions.length === 0 ?
                                            <i class="hasNoResults">No matching results</i>
                                            :
                                            <div class="list-autocomplete">
                                                {suggestions && suggestions.map((suggestion, index) =>
                                                    <div key={index}><li className='suggest-item' style={{ "padding": "2px 10px" }} onClick={(e) => suggestHandler(suggestion.title)}>{suggestion.title}</li></div>)}
                                            </div>
                                        }

                                    </div>
                                </div>
                            </form> */}
                  </div>
                </div>
                <div className="content-bottom mt-4">
                  {careers.length > 0 ? (
                    <>
                      <div className="navbar mt-4">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="all-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#all"
                              type="button"
                              role="tab"
                              aria-controls="all"
                              aria-selected="true"
                            >
                              All
                            </button>
                          </li>
                          {careerCategory.map((career, id) => {
                            return (
                              <li
                                key={id}
                                className="nav-item"
                                role="presentation"
                              >
                                <button
                                  className="nav-link"
                                  id={career.id}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#${career.slug}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={career.slug}
                                  aria-selected="false"
                                >
                                  {career.category}
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="all"
                          role="tabpanel"
                          aria-labelledby="all-tab"
                        >
                          <div className="content-main mt-4">
                            {careers.map((career, id) => {
                              return (
                                <div key={id} className="card mb-3">
                                  <div className="row g-0">
                                    <div className="col-md-8">
                                      <Accordion key={id} {...career} />
                                    </div>
                                    <div className="col-md-4">
                                      <Button
                                        variant="primary"
                                        onClick={() => handleShow(career.title)}
                                      >
                                        Apply Now
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {careerCategory.map((category, id) => {
                          return (
                            <div
                              className="tab-pane fade"
                              id={category.slug}
                              key={id}
                              role="tabpanel"
                              aria-labelledby={category.id}
                            >
                              <div className="content-main mt-4">
                                {careers
                                  .filter((career) =>
                                    career.category.includes(category.category)
                                  )
                                  .map((career, id) => {
                                    return (
                                      <div key={id} className="card mb-3">
                                        <div className="row g-0">
                                          <div className="col-md-8">
                                            <Accordion key={id} {...career} />
                                          </div>
                                          <div className="col-md-4">
                                            <Button
                                              variant="primary"
                                              onClick={() =>
                                                handleShow(career.title)
                                              }
                                            >
                                              Apply Now
                                            </Button>

                                            <Modal
                                              show={show}
                                              onHide={handleClose}
                                              size="md"
                                            >
                                              <Modal.Header closeButton>
                                                <Modal.Title>
                                                  Email Apply
                                                </Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                <Form
                                                  noValidate
                                                  validated={validated}
                                                >
                                                  <Form.Group className="mb-3 controlInput1">
                                                    <Form.Group controlId="NameInput">
                                                      <Form.Label>
                                                        Name
                                                      </Form.Label>
                                                      <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Enter Your Name"
                                                        autoFocus
                                                        style={{ margin: "0" }}
                                                        value={name}
                                                        onChange={(e) =>
                                                          setName(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        Please enter your name.
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="EmailInput">
                                                      <Form.Label>
                                                        Email address
                                                      </Form.Label>
                                                      <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder="Enter Your Email"
                                                        autoFocus
                                                        style={{ margin: "0" }}
                                                        value={email}
                                                        onChange={(e) =>
                                                          setEmail(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        Please enter your email.
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="mb-3"
                                                    controlId="CoverLetterInput"
                                                  >
                                                    <Form.Label>
                                                      Cover Letter
                                                    </Form.Label>
                                                    <Form.Control
                                                      as="textarea"
                                                      rows={3}
                                                      value={message}
                                                      onChange={(e) =>
                                                        setMessage(
                                                          e.target.value
                                                        )
                                                      }
                                                      required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      Cover Letter cannot be
                                                      empty.
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="mb-3"
                                                    controlId="fileInput"
                                                  >
                                                    <Form.Label>
                                                      Upload CV *
                                                    </Form.Label>
                                                    <Form.Control
                                                      required
                                                      type="file"
                                                      name="file"
                                                      style={{ height: "auto" }}
                                                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                      onChange={(e) =>
                                                        setCV(e.target.files[0])
                                                      }
                                                    />
                                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                                    <Form.Label>
                                                      * Please upload pdf,doc or
                                                      docx files only!
                                                    </Form.Label>
                                                  </Form.Group>
                                                </Form>
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button
                                                  variant="secondary"
                                                  onClick={handleClose}
                                                >
                                                  Close
                                                </Button>
                                                <Button
                                                  variant="primary"
                                                  onClick={handleSubmit}
                                                >
                                                  Apply
                                                </Button>
                                                <ToastContainer />
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <h4 className="text-secondary text-center">
                      There are no vacancies right now. Please visit later.
                    </h4>
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default Careers;
