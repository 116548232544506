import { Route, Routes } from "react-router-dom";
import Home from "./Home/home";
import Training from "./Training/training";
import Blogs from "./Blogs/blogs";
import BlogDetail from "./Blog-Detail/blog-detail";
import About from "./About/about";
import Careers from "./Career/careers";
import PageNotFound from "./404Page";
import Contact from "./Contact/contact";
import TrainingDetail from "./Training/training-detail";
import PrivacyPolicy from "./privacy-policy";

function PageRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/trainings" element={<Training></Training>} />
        <Route path="/trainings/:id" element={<TrainingDetail />} />
        <Route path="/blogs" element={<Blogs></Blogs>} />
        <Route path="/blogs/:id" element={<BlogDetail></BlogDetail>} />
        <Route path="/about" element={<About></About>} />
        <Route path="/careers" element={<Careers></Careers>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<Contact></Contact>} />
        <Route path="/contact-us/:id" element={<Contact></Contact>} />
        <Route path="*" element={<PageNotFound></PageNotFound>} />
      </Routes>
    </>
  );
}

export default PageRoutes;
