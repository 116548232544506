import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./pop-up.css";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import ReactQuill from "react-quill";
import { useEffect } from "react";
import { removeHTML } from "../../utils/util";

function PopUp(props) {
  const [show, setShow] = useState(false);
  const [popUp, setPopUp] = useState([]);
  const [popUpCms, setPopUpCms] = useState([]);

  const fetchData = async () => {
    const res = await makeRequest(`/popup`);
    if (res) {
      setPopUp(res?.data?.data);
    }
  };

  const fetchCmsData = async () => {
    const res = await makeRequest(`/cms/pop-up`);
    if (res) {
      setPopUpCms(res?.data?.data.childPages);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 6000);

    fetchData();
    fetchCmsData();
  }, [setShow]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <div className="popup">
          <div className="d-flex gap-3">
            <div className="col-6">
              <div className="popback">
                <div className="pop-image">
                  <img
                    src={
                      popUp.image
                        ? getFileUrl(popUp.image)
                        : "../assets/images/popup.png"
                    }
                    className="pop-image img-fluid"
                    style={{ objectFit: "cover" }}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div className="col pop-detail">
              <p className="btn-close" onClick={handleClose} />
              <div className="mt-5 mb-5">
                <h1 className="pop-h1">{popUp.title}</h1>
                <div className="mt-4 desc">
                  <ReactQuill
                    value={popUp.description}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </div>
                <Link
                  to={
                    `${removeHTML(popUpCms[0]?.description)}` ?? `/contact-us`
                  }
                >
                  <button className="pop-btn mt-5" onClick={handleClose}>
                    {popUpCms[0]?.title ?? "Enroll Now"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PopUp;
