import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";

function Training() {
  const [trainings, setTrainings] = useState([]);
  const [trainingCMS, setTrainingCMS] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/homepage`);
    if (res) {
      setTrainingCMS(res.data?.data.childPages[2]);
    }
  };

  const fetchItems = async () => {
    const params = new URLSearchParams();
    params.set("take", (3).toString());

    const res = await makeRequest(`/trainings?${params.toString()}`);
    if (res) {
      setTrainings(res.data?.data);
    }
  };

  useEffect(() => {
    fetchCMS();
    fetchItems();
  }, []);

  return (
    <section className="training">
      <div className="container training-wrapper text-center">
        <div className="content-header">
          <div className="content d-flex flex-column align-items-center">
            <h1 className="heading">
              {trainingCMS.title && trainingCMS.title}
            </h1>
            <p className="subheading col-md-8">
              {trainingCMS.description && removeHTML(trainingCMS.description)}
            </p>
          </div>
        </div>
        <div className="card-group">
          {trainings.length > 0 &&
            trainings.map((training, index) => {
              return (
                <Link key={index} className="card" to={`/trainings`}>
                  <img
                    src={
                      training.image
                        ? getFileUrl(training.image)
                        : "../assets/images/training-default.jpg"
                    }
                    alt=""
                    className="img-fluid"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      padding: "0.5rem",
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{training.title}</h5>
                    <p className="card-text">
                      {training?.description
                        ? removeHTML(training?.description)
                        : ""}
                    </p>
                  </div>
                </Link>
              );
            })}
        </div>
        <Link to={"/trainings"}>
          <button className="btn-see-all">See all trainings</button>
        </Link>
      </div>
    </section>
  );
}

export default Training;
