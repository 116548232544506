import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import ReactQuill from "react-quill";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  const fetchPrivacyCms = async () => {
    const res = await makeRequest(`/cms/privacy-policy`);
    if (res) {
      setPrivacyPolicy(res.data.data);
      console.log(res.data.data);
    }
  };

  useEffect(() => {
    fetchPrivacyCms();
  }, []);

  return (
    <>
      <Helmet>
        <title>RPA Nepal - Privacy Policy</title>
        <meta
          name="description"
          content={removeHTML(privacyPolicy?.metaDescription)}
        />
      </Helmet>
      <main>
        <section className="top-banner">
          <div className="container">
            <p>
              <ReactQuill
                value={privacyPolicy?.description}
                readOnly={true}
                theme={"bubble"}
              />
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default PrivacyPolicy;
