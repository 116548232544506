import { useEffect, useState } from "react";
import "./about.css";
import UseCase from "../Home/use-case";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import ContactUs from "../../components/contact-us/contact-us";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function About() {
  const [trainings, setTrainings] = useState([]);
  const [aboutUsCMS, setAboutUsCMS] = useState([]);
  const [implementationCMS, setImplementationCMS] = useState([]);
  const [implementationChildCMS, setImplementationChildCMS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTrainings = async () => {
    const params = new URLSearchParams();
    params.set("take", (3).toString());

    setIsLoading(true);
    const res = await makeRequest(`/trainings?${params.toString()}`);
    if (res) {
      setTrainings(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/about-us`);
    if (res) {
      setAboutUsCMS(res.data?.data);
    }

    const resp = await makeRequest(`/cms/implementation`);
    if (resp) {
      setImplementationCMS(resp.data?.data);
      setImplementationChildCMS(resp.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchTrainings();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>About RPA Nepal</title>
        <meta name="description" content={aboutUsCMS.metaDescription} />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <section className="about">
              <div className="container-fluid px-0 about-wrapper">
                <div className="content-header">
                  <h1>
                    {aboutUsCMS.description &&
                      removeHTML(aboutUsCMS.description)}
                  </h1>
                </div>
                <div className="about-banner">
                  <img
                    src={aboutUsCMS.image ? getFileUrl(aboutUsCMS.image) : ""}
                    alt=""
                  />
                </div>
              </div>
            </section>
            {trainings.length > 0 && (
              <section className="training-tutorial">
                <div className="container training-tutorial-wrapper">
                  <div className="content-header">
                    <h1 className="heading">RPA Academy</h1>
                  </div>
                  <div className="card-group">
                    {trainings.map((item, id) => {
                      return (
                        <Link key={id} className="card" to={`/trainings`}>
                          <img
                            src={
                              item.image
                                ? getFileUrl(item.image)
                                : "assets/images/training-default.jpg"
                            }
                            className="img-fluid"
                            style={{ height: "200px", objectFit: "cover" }}
                            alt=""
                          />
                          <div className="card-body">
                            <h5 className="card-title">{item.title}</h5>
                            <p className="card-text">
                              {item.description && removeHTML(item.description)}
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </section>
            )}
            <UseCase></UseCase>
            {implementationChildCMS.length > 0 && (
              <section className="implementation">
                <div className="container implementation-wrapper">
                  <div className="content-header">
                    <div className="content">
                      <span>{implementationCMS.title}</span>
                      <h1 className="heading">
                        {implementationCMS.description &&
                          removeHTML(implementationCMS.description)}
                      </h1>
                    </div>
                  </div>
                  <div className="content-main">
                    <div className="workflow-step">
                      <img
                        src="assets/images/1.png"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="details">
                        <h1>
                          {implementationChildCMS[0] &&
                            implementationChildCMS[0].title}
                        </h1>
                        <p>
                          {removeHTML(
                            implementationChildCMS[0] &&
                              implementationChildCMS[0].description
                          )}
                        </p>
                      </div>
                      <img
                        src={getFileUrl(implementationChildCMS[0].image)}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="workflow-step-center">
                      <img
                        src="assets/images/2.png"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="details">
                        <h1>
                          {implementationChildCMS[1] &&
                            implementationChildCMS[1].title}
                        </h1>
                        <p>
                          {removeHTML(
                            implementationChildCMS[1] &&
                              implementationChildCMS[1].description
                          )}
                        </p>
                      </div>
                      <img
                        src={getFileUrl(
                          implementationChildCMS[1] &&
                            implementationChildCMS[1].image
                        )}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="workflow-step">
                      <img
                        src="assets/images/3.png"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="details">
                        <h1>
                          {implementationChildCMS[2] &&
                            implementationChildCMS[2].title}
                        </h1>
                        <p>
                          {removeHTML(
                            implementationChildCMS[2] &&
                              implementationChildCMS[2].description
                          )}
                        </p>
                      </div>
                      <img
                        src={
                          implementationChildCMS[2].image &&
                          getFileUrl(implementationChildCMS[2].image)
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}

        <section className="about-contact-us">
          <ContactUs
            data={{
              background: "#E9F5F7",
              color: "#2C3739",
              filter: "none",
            }}
          ></ContactUs>
        </section>
      </main>
    </>
  );
}

export default About;
