import { useState } from "react";
import { ParseHTML } from "../../utils/util";

function Accordion(career) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className="card-body">
      <h5 className="card-title">{career.title}</h5>
      <h6>Application Deadline: {career.publishDate}</h6>
      <details>
        {career.description ? (
          <summary className="mt-3" onClick={() => setShowDetail(!showDetail)}>
            {showDetail ? "View Less" : "View More"}
          </summary>
        ) : (
          ""
        )}
        <div className="card-text mt-4">
          <small>{ParseHTML(career.description ?? "")}</small>
        </div>
      </details>
    </div>
  );
}

export default Accordion;
