import ContactUs from "../../components/contact-us/contact-us";
import "./contact.css";

function Contact() {
  return (
    <>
    <section className="contact-us">
        <ContactUs data={{color: "#FFFFFF"}}></ContactUs>
    </section>
    </>
  );
}

export default Contact;
