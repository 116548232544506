import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [blogCMS, setBlogCMS] = useState([]);
  const [topBlog, setTopBlog] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/homepage`);
    if (res) {
      setBlogCMS(res.data?.data.childPages[3]);
    }
  };

  const fetchBlogs = async () => {
    const params = new URLSearchParams();
    params.set("take", (3).toString());

    const res = await makeRequest(`/blogs?${params.toString()}`);
    if (res) {
      setBlogs(res.data?.data);
      setTopBlog(res.data?.data[0]);
    }
  };

  useEffect(() => {
    fetchCMS();
    fetchBlogs();
  }, []);

  return (
    <>
      {blogs.length > 0 && (
        <section className="home-blogs">
          <div className="container home-blogs-wrapper">
            <div className="content-top">
              <div className="col-lg-4 content-left">
                <span>BLOGS</span>
                <h1>{blogCMS && blogCMS.title}</h1>
                <p>{blogCMS && removeHTML(blogCMS.description)}</p>
                <Link to={"/blogs"}>
                  <button className="btn-read">Read all blogs</button>
                </Link>
              </div>
              <div className="col-lg-7 content-right">
                <Link to={`/blogs/${topBlog.slug}`}>
                  <div className="card" style={{ cursor: "pointer" }}>
                    <div className="row no-gutters">
                      <div className="col-md-4">
                        <img
                          src={
                            topBlog.image
                              ? getFileUrl(topBlog.image)
                              : "assets/images/training-default.jpg"
                          }
                          className="card-img"
                          style={{ height: "100%", objectFit: "cover" }}
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                        {topBlog.tags !== "undefined" && (
                                <div className="tags gap-2 mb-2">
                                  {topBlog.tags !== "undefined" ? (
                                    <div className="tags gap-2 mb-2">
                                      {topBlog.tags
                                        .split(",")
                                        .map((tag, index) => (
                                          <small
                                            className="card-text"
                                            key={index}
                                          >
                                            {tag}
                                          </small>
                                        ))}
                                    </div>
                                  ) : (
                                    <small
                                      style={{
                                        background: "white",
                                      }}
                                    ></small>
                                  )}
                                </div>
                              )}
                          <h5 className="card-title">{topBlog.title}</h5>
                          <p className="card-text">
                            {removeHTML(topBlog.description).substring(0, 200)}
                            <strong>...</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="card-group mt-lg-5 mt-md-5">
              {blogs.length > 0 &&
                blogs
                  .filter((blog) => blog.id !== topBlog.id)
                  .map((blog, index) => {
                    return (
                      <div key={index} className="col">
                        <Link to={`/blogs/${blog.slug}`}>
                          <div className="card" style={{ cursor: "pointer" }}>
                            <img
                              src={
                                blog.image
                                  ? getFileUrl(blog.image)
                                  : "../assets/images/blog1.png"
                              }
                              className="card-img-top"
                              alt="..."
                            />{" "}
                            <div className="card-body">
                              {blog.tags !== "undefined" && (
                                <div className="tags gap-2 mb-2">
                                  {blog.tags !== "undefined" ? (
                                    <div className="tags gap-2 mb-2">
                                      {blog.tags
                                        .split(",")
                                        .map((tag, index) => (
                                          <small
                                            className="card-text"
                                            key={index}
                                          >
                                            {tag}
                                          </small>
                                        ))}
                                    </div>
                                  ) : (
                                    <small
                                      style={{
                                        background: "white",
                                      }}
                                    ></small>
                                  )}
                                </div>
                              )}
                              <h5 className="card-title">{blog.title}</h5>
                              <p className="card-text">
                                {removeHTML(blog.description).substring(0, 200)}
                                <strong>...</strong>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Blogs;
