import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";

function Features() {
  const [featuresCms, setFeaturesCms] = useState([]);
  const [featureChildCms, setFeatureChildCms] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/services`);
    if (res) {
      setFeaturesCms(res.data?.data);
      setFeatureChildCms(res.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  return (
    <>
      <section className="features" id="features">
        <div className="container features-wrapper">
          <div className="content-header">
            <div className="content d-flex flex-column align-items-center">
              <span className="heading">{featuresCms.title}</span>
              <h1 className="col-md-8">
                {featuresCms.description && removeHTML(featuresCms.description)}
              </h1>
            </div>
          </div>
          {featureChildCms.length > 0 && (
            <div className="content-main">
              <div className="card-group g-1">
                  {featureChildCms.map((child, index) => {
                    return (
                      // <div key={index} className="col">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">{child.title}</h5>
                            <p className="card-text">
                              {removeHTML(child.description)}
                            </p>
                          </div>
                        </div>
                      // </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Features;
