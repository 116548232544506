import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
function UseCase() {
  const [UseCasesCms, setUseCaseCms] = useState([]);
  const [UseCasesChildCms, setUseCaseChildCms] = useState([]);
  const [UseCase, setUseCase] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/learn-from-the-best`);
    if (res) {
      setUseCaseCms(res.data?.data);
      setUseCaseChildCms(res.data?.data?.childPages);
      setUseCase(res.data?.data?.childPages[0]);
    }
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  return (
    <>
      {UseCasesCms && (
        <section className="usecase">
          <div className="container usecase-wrapper">
            <div className="col-lg-4 col-md-12 content-left">
              <span>{UseCasesCms.title ?? ""}</span>
              <h1 className="heading">{UseCase.title}</h1>
              <p className="subheading">
                {removeHTML(UseCase.description ?? "")}
              </p>
              <Link to="/contact-us">
                <button className="btn-contact">Contact Sales</button>
              </Link>
            </div>
            {UseCasesChildCms.length > 0 && (
              <div className="content-right col-lg-7">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {UseCasesChildCms[1] && (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id={UseCasesChildCms[1].id}
                        data-bs-toggle="tab"
                        data-bs-target={`#${UseCasesChildCms[1].slug}`}
                        type="button"
                        role="tab"
                        aria-controls={UseCasesChildCms[1].slug}
                        aria-selected="true"
                      >
                        {UseCasesChildCms[1].title}
                      </button>
                    </li>
                  )}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#all"
                      type="button"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                    >
                      All
                    </button>
                  </li> */}
                  {UseCasesChildCms.filter(
                    (child) =>
                      child.id !== UseCase.id &&
                      child.id !== UseCasesChildCms[1].id
                  ).map((child, index) => {
                    return (
                      <li key={index} className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id={child.id}
                          data-bs-toggle="tab"
                          data-bs-target={`#${child.slug}`}
                          type="button"
                          role="tab"
                          aria-controls={child.slug}
                          aria-selected="false"
                        >
                          {child.title}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                <div className="tab-content" id="myTabContent">
                  {UseCasesChildCms[1] && (
                    <div
                      className="tab-pane fade show active"
                      id={UseCasesChildCms[1].slug}
                      role="tabpanel"
                      aria-labelledby={UseCasesChildCms[1].id}
                    >
                      <div className="content-bottom mt-4">
                        <ReactPlayer
                          controls
                          width="100%"
                          url={UseCasesChildCms[1].description}
                        />
                      </div>
                    </div>
                  )}
                  {UseCasesChildCms.filter(
                    (child) =>
                      child.id !== UseCase.id &&
                      child.id !== UseCasesChildCms[1].id
                  ).map((child, index) => {
                    return (
                      <div
                        className="tab-pane fade"
                        id={child.slug}
                        key={index}
                        role="tabpanel"
                        aria-labelledby={child.id}
                      >
                        <div className="content-bottom mt-4">
                          <ReactPlayer
                            controls
                            width="100%"
                            url={child.description}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default UseCase;
