import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";

function Testimonial() {
  const [testimonialCms, setTestimonialCms] = useState([]);
  const [testimonialChildCms, setTestimonialChildCms] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/meet-our-team-members`);
    if (res) {
      setTestimonialCms(res.data?.data);
      setTestimonialChildCms(res.data?.data.childPages[0]);
    }
  };

  const fetchItems = async () => {
    const res = await makeRequest(`/testimonials`);
    if (res) {
      const data = res?.data?.data;
      const filter = data.filter((data) => data.category === "Home").splice(0, 2);
      setTestimonials(filter);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchCMS();
  }, []);

  return (
    <>
      <section className="testimonials">
        <div className="container testimonials-wrapper">
          <div className="col-lg-4 col-md-12 content-left">
            <span>{testimonialCms.title}</span>
            {testimonialChildCms && (
              <>
                <h1 className="heading">{testimonialChildCms?.title}</h1>
                <p className="subheading">
                  {testimonialChildCms.description &&
                    removeHTML(testimonialChildCms.description)}
                </p>
              </>
            )}
          </div>
          {testimonials.length > 0 && (
            <div className="content-right col-lg-7">
              {testimonials.map((testimonial, index) => {
                return (
                  <div className="quote-top" key={index}>
                    <img
                      className="quotation"
                      src="assets/images/quotation-mark.png"
                      alt=""
                    />
                    <p className="subheading">{testimonial.description}</p>
                    <div className="profile">
                      <div className="profile-details">
                        <h2 className="name">{testimonial.name}</h2>
                        <h3 className="position">{testimonial.designation}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Testimonial;
