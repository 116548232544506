import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.css";
import Logo from "./brand-logo.png";
import getFileUrl from "../../utils/getFileUrl";
import makeRequest from "../../utils/api";
import { RxHamburgerMenu } from "react-icons/rx";

function Header() {
  const [siteSettings, setSiteSettings] = useState([]);

  const fetchCMS = async () => {
    const resp = await makeRequest(`/site-settings`);
    if (resp) {
      setSiteSettings(resp.data?.data);
    }
  };

  useEffect(() => {
    fetchCMS();
    let nav = document.querySelector(".navigation-wrap");
    window.onscroll = function () {
      if (document.documentElement.scrollTop > 20) {
        nav.classList.add("scroll-on");
      } else {
        nav.classList.remove("scroll-on");
      }
    };

    let navBar = document.querySelectorAll(".nav-link");
    let navCollapse = document.querySelector(".navbar-collapse.collapse");
    navBar.forEach(function (a) {
      a.addEventListener("click", function () {
        navCollapse.classList.remove("show");
      });
    });
  }, []);

  return (
    <header>
      <nav
        className="navbar navbar-expand-lg navigation-wrap"
        id="navigation-wrap"
      >
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={
                siteSettings.headerLogo
                  ? getFileUrl(siteSettings.headerLogo)
                  : Logo
              }
              alt=""
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <RxHamburgerMenu className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/trainings">
                  Academy
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/careers">
                  Career
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">
                  <button className="contact-btn">Contact Sales</button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
