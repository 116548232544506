import "./home.css";
import Features from "./features";
import Testimonial from "./testimonial";
import Training from "./training";
import UseCase from "./use-case";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Blogs from "./blogs";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import Contact from "../Contact/contact";
import { Helmet } from "react-helmet";
import PopUp from "../../components/pop-up/pop-up";

function Home() {
  const [homeCMS, setHomeCMS] = useState({});
  const [homeBanner, setHomeBanner] = useState({});
  const [partnersCMS, setPartnersCMS] = useState([]);
  const [whatWeDoCMS, setWhatWeDoCMS] = useState({});
  const [whatWeDoChild, setWhatWeDoChild] = useState([]);
  const [featuresBanner, setFeaturesBanner] = useState([]);
  const [featureBannerChild, setFeatureBannerChild] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plansAndPricings, setPlansAndPricings] = useState([]);

  const fetchCMS = async () => {
    setIsLoading(true);
    const resp = await makeRequest(`/cms/homepage`);
    if (resp) {
      setHomeCMS(resp.data?.data);
      setHomeBanner(resp.data?.data.childPages[0]);
      setIsLoading(false);
    }

    const res = await makeRequest(`/cms/our-partners`);
    if (res) {
      setPartnersCMS(res.data?.data.childPages);
    }

    const response = await makeRequest(`/cms/what-we-do`);
    if (response) {
      setWhatWeDoCMS(response.data?.data);
      setWhatWeDoChild(response.data?.data.childPages);
    }

    const respo = await makeRequest(`/cms/rpa-is-widely-used`);
    if (respo) {
      setFeaturesBanner(respo.data?.data);
      setFeatureBannerChild(respo.data?.data.childPages);
    }
  };

  const fetchPlansAndPricingCMS = async () => {
    const res = await makeRequest(`/cms/plans-and-pricing`);
    if (res) {
      setPlansAndPricings(res.data?.data);
    }
  };

  useEffect(() => {
    fetchPlansAndPricingCMS();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>RPA NEPAL</title>
        <meta name="description" content={homeCMS.metaDescription} />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <section className="home" id="home">
              <div className="container-fluid px-0 home-wrapper">
                <div className="container home-banner">
                  <div className="col-lg-5 col-md-5 col-6 content-left">
                    <h1 className="heading">{homeBanner.title}</h1>
                    <p className="subheading">
                      {homeBanner.description &&
                        removeHTML(homeBanner.description)}
                    </p>
                    <div className="btn-group mb-lg-4">
                      <NavLink to="/contact-us">
                        <button className="btn-contact">Contact Sales</button>
                      </NavLink>
                      {/* <button className="btn-see-more ms-lg-4 mt-4">
                        See how it works
                      </button> */}
                    </div>
                    {partnersCMS.length > 0 && (
                      <div className="content-bottom mt-5">
                        <div className="content">
                          <h1 className="heading">Our Partners:</h1>
                          <div className="partners px-4">
                            {partnersCMS.map((child, index) => {
                              return (
                                <img
                                  className="img-fluid col-md-6 col-10"
                                  style={{ filter: "grayscale(1)" }}
                                  src={getFileUrl(child.image)}
                                  alt={index}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <img
                      className="img-fluid"
                      src={
                        homeBanner.image
                          ? getFileUrl(homeBanner.image)
                          : "assets/images/home-banner.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="what-we-do">
              <div className="container-fluid px-0 what-we-do-wrapper">
                <div className="content-header text-center">
                  <h1>
                    {whatWeDoCMS.description &&
                      removeHTML(whatWeDoCMS.description)}
                  </h1>
                </div>
                <div className="container">
                  <div className="card-group">
                    {whatWeDoChild.length > 0 &&
                      whatWeDoChild.map((child, index) => {
                        return (
                          <div className="card" key={index}>
                            <small>
                              <img
                                src={getFileUrl(child.image)}
                                alt=""
                                width={80}
                              />
                            </small>
                            <div className="card-body">
                              <h5 className="card-title">{child.title}</h5>
                              <p className="card-text">
                                {removeHTML(child.description)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <Testimonial></Testimonial>
            <UseCase></UseCase>
            <Features></Features>
            {featuresBanner.title && (
              <section className="features-banner">
                <div className="container features-banner-wrapper">
                  <div className="content-left">
                    <h1 className="heading">{featuresBanner.title}</h1>
                    <p className="subheading">
                      {featuresBanner.description &&
                        removeHTML(featuresBanner.description)}
                    </p>
                  </div>
                  <div className="content-center">
                    {featureBannerChild.length > 0 &&
                      featureBannerChild.map((child, index) => {
                        return (
                          <div key={index} className="content">
                            <h1>{removeHTML(child.description)}+</h1>
                            <span>{child.title}</span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="content-right">
                    <Link to={"/contact-us"}>
                      <button className="btn-contact">Contact Sales</button>
                    </Link>
                  </div>
                </div>
              </section>
            )}
            {/* <section className="plans" id="plans">
              <div className="container-fluid px-0 plans-and-pricing-wrapper">
                <PlansAndPricing
                  data={{
                    title: plansAndPricings.title,
                    description: plansAndPricings.description,
                    background: "#FFF1E8",
                    color: "#FF6F1E",
                  }}
                ></PlansAndPricing>
              </div>
            </section> */}
            <Training></Training>
            <Contact data={{ color: "#FFFFFF" }}></Contact>
            <Blogs></Blogs>
          </>
        )}
      </main>
    </>
  );
}

export default Home;
