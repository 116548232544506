import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import "./footer.css";
import { ImFacebook } from "react-icons/im";
import { IoLogoInstagram } from "react-icons/io";
import { AiFillLinkedin } from "react-icons/ai";

function Footer() {
  const [siteSettings, setSiteSettings] = useState([]);
  const [newsLetterCMS, setNewsLetterCMS] = useState([]);
  const [footerCMS, setFooterCMS] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/signup-to-our-newsletters`);
    if (res) {
      setNewsLetterCMS(res.data?.data);
    }

    const resp = await makeRequest(`/cms/footer`);
    if (resp) {
      setFooterCMS(resp.data?.data);
    }
  };

  const fetchItems = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSiteSettings(res.data?.data);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchCMS();
  }, []);

  return (
    <footer className="footer">
      <div className="container footer-wrapper">
        <div className="content-main">
          <div className="content-left">
            <h1>DIVE DEEP INTO RPANEPAL</h1>
            <ul className="menu">
              <Link to="/about">
                <li className="menu-item">About RPANEPAL</li>
              </Link>
              <Link to="/blogs">
                <li className="menu-item">Blogs</li>
              </Link>
              <Link to="/careers">
                <li className="menu-item">Career</li>
              </Link>
              <Link to="/privacy-policy">
                <li className="menu-item">Privacy Policy</li>
              </Link>
            </ul>
          </div>
          <div className="content-center">
            <h1>GET IN TOUCH</h1>
            <ul className="contact-details">
              <li className="menu-item">{siteSettings.email}</li>
              <li className="menu-item">{siteSettings.phone}</li>
              <li className="menu-item">{siteSettings.address}</li>
            </ul>
            <ul className="social-media">
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.facebook}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <ImFacebook /> facebook
                </a>
              </li>
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.twitter}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <IoLogoInstagram /> instagram
                </a>
              </li>
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <AiFillLinkedin /> linkedIn
                </a>
              </li>
            </ul>
          </div>
          <div className="content-right">
            {/* <h1>SIGNUP FOR NEWSLETTERS</h1> */}
            <p className="subheading" style={{ color: "#FFFFFF" }}>
              {removeHTML(newsLetterCMS.description ?? "")}
            </p>
            {/* <form className="row g-3">
              <div className="col-auto">
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Your email"
                  onKeyPress={handleKeyPress}
                />
              </div>
            </form> */}
          </div>
        </div>
      </div>
      <div className="content-bottom">
        <div className="content">
          <img
            src={
              siteSettings.footerLogo ? getFileUrl(siteSettings.footerLogo) : ""
            }
            alt=""
          />
          <p className="subheading" style={{ color: "#FFFFFF" }}>
            {removeHTML(footerCMS.description ?? "")}
          </p>
          <span>Copyright 2021 @RPANEPAL</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
